<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-revenue header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
			<template #title>
				<span class="custom-title-left">Revenue Sharing</span>
			</template>
			<div class="sign-up-gateways">
				<p class="text-center my-25 text-lg text-muted custom-description">You must hold 1.000.000 $BTR to claim
				</p>
			</div>
			<!-- <p class="text-center my-25 font-semibold text-muted">Or</p> -->
			<a-form id="components-claim-tokens" class="claim-tokens">
				<a-form-item>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="security-scan" theme="outlined" class="" />
							</span>
							<span class="text mr-2">
								Pool Total
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ poolTotal }} ETH
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="heat-map" theme="outlined" class="" />
							</span>
							<span class="text">
								Next Share Total
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ rewardClaimed }} ETH
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="clock-circle" theme="outlined" class="" />
							</span>
							<span class="text">
								Next Share Unlock
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ timeUnlock ? timeUnlock : startDate }}
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="text p-10">
								Balance
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ balanceAddress }} $BTR
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="text p-10">
								Claimed
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ totalClaimed }} $BTR
						</a-col>
					</a-row>

					<a-button type="primary" :disabled="!isClaimed" @click="saveData" block
						class="claim-tokens-button text-uppercase">
						Claim
					</a-button>

				</a-form-item>
			</a-form>
		</a-card>
		<!-- / Sign Up Form -->

		<div class="loading" v-if="loading">
		</div>

	</div>
</template>

<script>
// web3 
import moment from 'moment';
import contractAbi from '../abi/contract.json';

export default ({
	data() {
		return {
			timeUnlock: '',
			poolTotal: '',
			shareTotal: 0,
			rewardClaimed: 0.005,
			// rewardClaimed: 10,
			totalClaimed: 0,
			amountAddress: 0,
			tokenAddress: '',
			balanceAddress: 0,
			decimals: 0,
			isClaimed: true,
			loading: false,
			// minimumAmount: 1,
			minimumAmount: 1000000,
			// supply: 300000000,
			startDate: '',
			serviceApi: '',
			rpcNode: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
		}
	},
	beforeCreate() {
	},
	mounted() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
	},
	created() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
		this.updateTime();
		this.checkAddress();
		this.checkPools()
		setInterval(() => {
			this.checkAddress();
			this.updateTime();
			this.checkPools();
		}, 1000);
	},
	methods: {
		// event every 3 days to update the time
		updateTime() {
			this.startDate = moment().format('Do YYYY, h:mm:ss a');
		},
		checkPools() {
			this.decimals = localStorage.getItem('decimals');
			this.poolTotal = localStorage.getItem('poolTotal');
			console.log('decimals:', this.decimals);
			console.log('poolTotal:', this.poolTotal);
		},
		async claimTokens(body) {
			try {
				let response = await this.$axios.post(`${this.serviceApi}/api/claim-tokens/`, body);
				console.log(response.data);

				if (response.data.status == 'success') {
					this.$swal({
						title: 'Success',
						text: 'Claimed successfully',
						icon: 'success',
						button: 'OK',
					});
					this.isClaimed = false;
					this.checkAddress();
				} else {
					this.$swal({
						title: 'Error',
						text: `A Claimed failed ${response.data.message}`,
						icon: 'error',
						button: 'OK',
					});
				}
			} catch (error) {
				console.log(error);
				this.$swal({
					title: 'Error',
					text: `B Claimed failed ${error}`,
					icon: 'error',
					button: 'OK',
				});
			}
		},
		async saveData() {
			this.loading = true;
			let received = this.$crypto.AES.encrypt(localStorage.getItem('address'), "lazy").toString();
			let finalAmount = this.$crypto.AES.encrypt(this.rewardClaimed.toString(), "lazy").toString();
			let body = {
				address: received,
				amount: finalAmount,
			}
			this.$axios.post(`${this.serviceApi}/send-token/`, body)
				.then((response) => {
					console.log(response);
					this.loading = false;
					const startDate = moment().format('YYYY-MM-DD');
					const endDate = moment(startDate).add(3, 'days').format('YYYY-MM-DD');

					if (response.data.status == 'success') {
						let bodyClaimTokens = {
							token_address: this.tokenAddress,
							address: localStorage.getItem('address'),
							date_claimed: moment().format('YYYY-MM-DD HH:mm:ss'),
							tx_hash: response.data.message,
							amount: this.rewardClaimed,
							next_claim_date: endDate,
							balance_before: this.balanceAddress,
							balance: this.balanceAddress,
						}
						this.claimTokens(bodyClaimTokens);
					} else {
						this.$swal({
							title: 'Error',
							text: `C Claimed failed ${response.data.message}`,
							icon: 'error',
							button: 'OK',
						});
					}
				})
				.catch((error) => {
					console.log(error);
					this.$swal({
						title: 'Error',
						text: `D Claimed failed ${error}`,
						icon: 'error',
						button: 'OK',
					});
				})
		},
		checkAddress() {
			this.balanceAddress = localStorage.getItem('balanceAddress');
			this.balanceAddress = parseFloat(this.balanceAddress).toFixed(0);
			console.log('balanceAddress:', this.balanceAddress);

			if (this.minimumAmount > this.balanceAddress) {
				this.isClaimed = false;
			}
			let params = {
				address: localStorage.getItem('address'),
			}
			this.$axios.get(`${this.serviceApi}/api/claim-tokens/sum`, { params })
				.then((response) => {
					console.log("response.data:", response.data);
					// if response.data empty then claim 
					if (response.data.length == 0) {
						console.log('C:', "not claimed");
						if (this.minimumAmount > this.balanceAddress) {
							console.log('less than 500k');
							this.isClaimed = false;
						}
					} else {
						console.log('A:', "already claimed");
						let nextClaimDate = response.data[0].next_claim_date;
						nextClaimDate = moment(nextClaimDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

						this.totalClaimed = response.data[0].total_amount;
						console.log('nextClaimDate:', nextClaimDate);

						let dateNow = moment().format('YYYY-MM-DD');
						console.log('dateNow:', dateNow);

						if (dateNow == nextClaimDate) {
							console.log('1:', "already claimed");
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
							this.isClaimed = true;
						} else if (dateNow > nextClaimDate) {
							console.log('2:', "already claimed");
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
							this.isClaimed = true;
						} else if (dateNow < nextClaimDate) {
							console.log('3:', "already claimed");
							this.isClaimed = false;
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
						} else {
							console.log('4:', "already claimed");
							this.isClaimed = true;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})


		}
	},
})

</script>

<style lang="scss" scoped>
.custom-title-left {
	text-transform: uppercase;

}

.icon-text {
	// text with icon in left
	position: relative;
	top: 2px;
	margin-right: 5px;


}

.text {
	font-size: 12px;
	position: relative;
	font-weight: 700;


}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>